import React, { useState, useEffect } from "react";
import { client, renderComponents } from "../client";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

function VideoGalleryContent(props) {
  let mounted = true;
  const [galeriIcerigi, setGaleriIcerigi] = useState("");

  useEffect(() => {
    document.title = props.title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntry("2ExcaTTbtDjtSPe5Sk0f9d")
      .then((response) => {
        if (mounted) {
          setGaleriIcerigi(response.fields.videoGaleri);
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, []);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{props.title}</h1>
      <div className="grid gap-x-4 gap-y-8 justify-between grid-cols-1 md:grid-cols-2 xl:grid-cols-3">{renderComponents(galeriIcerigi, { [BLOCKS.PARAGRAPH]: (node, next) => "" })}</div>
    </div>
  );
};

export default VideoGalleryContent;