import React, { useEffect } from "react";
import Left from "../Sections/Left";
import Right from "../Sections/Right";

function Main(props) {
  const horizontalMargin = props.noMargin === undefined ? " mx-2 md:mx-0 " : " ";
  return (
    <main className="grid gap-x-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
      <Left />
      <section className={"mb-3" + horizontalMargin + "col-span-1 md:col-span-2 lg:col-span-3 order-first lg:order-none"}>
        {props.content}
      </section>
      <Right />
    </main>
  );
};

export default Main;