import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Top from "./Sections/Top";
import Bottom from "./Sections/Bottom";
import Main from "./Sections/Main";
import HomepageContent from "./Views/HomepageContent";
import GeneralTextualContent from "./Views/GeneralTextualContent";
import InvestmentContent from './Views/InvestmentContent';
import NewsContent from './Views/NewsContent';
import AllNewsContent from './Views/AllNewsContent';
import ProjectContent from './Views/ProjectContent';
import InvestmentsContent from './Views/InvestmentsContent';
import ContactContent from './Views/ContactContent';
import VideoGalleryContent from './Views/VideoGalleryContent';
import PhotoGalleryContent from './Views/PhotoGalleryContent';
import PublishedWorksContent from './Views/PublishedWorksContent';

function App() {
  return (
    <Router>
      <Top />
      <Switch>
        <Route exact path="/">
          <Main content={<HomepageContent />} noMargin />
        </Route>
        <Route path="/hakkinda">
          <Main content={<GeneralTextualContent title="Hakkında" />} />
        </Route>
        <Route path="/faaliyetler">
          <Main content={<GeneralTextualContent title="Faaliyetler" />} />
        </Route>
        <Route path="/il-yatirimlari/:province">
          <Main content={<InvestmentContent />} />
        </Route>
        <Route path="/il-yatirimlari">
          <Main content={<InvestmentsContent title="İl Yatırımları" />} />
        </Route>
        <Route path="/yurtici-seyahatler">
          <Main content={<PhotoGalleryContent galleryType="yurtici" />} />
        </Route>
        <Route path="/yurtdisi-seyahatler">
          <Main content={<PhotoGalleryContent galleryType="yurtdisi" />} />
        </Route>
        <Route path="/video-galeri">
          <Main content={<VideoGalleryContent title="Video Galeri" />} />
        </Route>
        <Route path="/iletisim">
          <Main content={<ContactContent title="İletişim" />} />
        </Route>
        <Route path="/haberler/:news">
          <Main content={<NewsContent />} />
        </Route>
        <Route path="/haberler">
          <Main content={<AllNewsContent title="Tüm Haberler" />} />
        </Route>
        <Route path="/projeler/:project">
          <Main content={<ProjectContent />} />
        </Route>
        <Route path="/basili-eserleri">
          <Main content={<PublishedWorksContent title="Basılı Eserleri" />} />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Bottom />
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));