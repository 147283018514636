import React from "react";
import * as contentful from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

export const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN
});

const renderOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "blogPost") {
        return (
          <a href={`/blog/${node.data.target.fields.slug}`}>
            {node.data.target.fields.title}
          </a>
        );
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a href={node.data && node.data.target.fields.file.url ? node.data.target.fields.file.url : null}
          target="_blank"
          style={{ color: "#0000EE", textDecoration: "underline" }}> {children} </a>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      // target the contentType of the EMBEDDED_ENTRY to display as you need
      if (node.data.target.sys.contentType.sys.id === "codeBlock") {
        return (
          <pre>
            <code>{node.data.target.fields.code}</code>
          </pre>
        );
      }

      if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
        return (
          <div className="bg-gray-200 rounded-md overflow-hidden shadow-lg border-gray-400 border-1">
            <div className="videoEmbed">
              <iframe
                src={"https://www.youtube.com/embed/" + node.data.target.fields.videoId}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                scrolling="no"
                title={node.data.target.fields.title}
                allowFullScreen={true} />
            </div>
            <span className="inline-block w-full px-2 pt-1 pb-1 font-semibold">{node.data.target.fields.title}</span>
            <span className="inline-block w-full text-sm px-2 pb-4">{node.data.target.fields.description}</span>
          </div>
        );
      }

      if (node.data.target.sys.contentType.sys.id === "videoInline") {
        return (
          <div className="w-full">
            <div className="videoInline mx-auto">
              <iframe
                src={"https://www.youtube.com/embed/" + node.data.target.fields.videoId}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                scrolling="no"
                title={node.data.target.fields.title}
                allowFullScreen={true} />
            </div>
          </div>
        );
      }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // render the EMBEDDED_ASSET as you need
      if (node.data.target.fields.file.details.image !== undefined) {
        return (
          <span className="block w-full text-center">
            <img
              src={`https://${node.data.target.fields.file.url}`}
              alt={node.data.target.fields.description}
              style={{
                width: node.data.target.fields.file.details.image.width,
              }}
              className="inline-block mb-4 max-w-full md:max-w-8/10" />
          </span>
        );
      }
    },
  },
};

export function renderComponents(richTextDocument, additionalRenderOptions = {}) {
  return documentToReactComponents(
    richTextDocument,
    { renderNode: { ...renderOptions.renderNode, ...additionalRenderOptions } }
  );
}