export function correctDateFormat(date) {
  if (date === "") {
    return "";
  }

  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık"
  ];

  const dateParts = date.split("-");
  return dateParts[2] + " " + months[parseInt(dateParts[1] - 1)] + " " + dateParts[0];
}