import React, { useEffect } from "react";

function TwitterTimeline(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  return (
    <div className="twitter-embed">
      <a className="twitter-timeline" data-dnt="true" data-tweet-limit="1" data-chrome="nofooter noheader"
        href={"https://twitter.com/" + props.username}></a>
    </div>
  );
};

export default TwitterTimeline;