import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SliderImage from "../Components/SliderImage";
import { client } from "../client";
import CountUp from 'react-countup';

let menuVisibility, setMenuVisibility;

function toggleMenuVisibility() {
  setMenuVisibility(!menuVisibility);
}

function Top(props) {
  let mounted = true;

  const location = useLocation();

  [menuVisibility, setMenuVisibility] = useState(false);
  const [projeSayilari, setProjeSayilari] = useState({
    baraj: "",
    hes: "",
    goletVeBent: "",
    icmeSuyu: "",
    milyarFidan: "",
    milliPark: ""
  });
  const [kayanFotograflar, setKayanFotograflar] = useState([]);

  useEffect(() => {
    client.getEntry("77KF3mbpWLKSeTVXHOBJc4")
      .then((response) => {
        if (mounted) {
          setProjeSayilari(response.fields);
        }
      })
      .catch(console.error);

    client.getEntry("61bYjtRI29hdQzsDXTHAeM")
      .then((response) => {
        if (mounted) {
          setKayanFotograflar(response.fields.kayanFotograflar.map(kayanFotograf => kayanFotograf.fields.file.url));
        }
      })
      .catch(console.error);

    const slider = document.querySelector('.slider');
    let counter = 1;
    let slideAmount;

    setInterval(() => {
      if (counter >= slider.childElementCount) {
        counter = 0;
      }
      slideAmount = -1 * counter * slider.clientWidth - 0.5;
      slider.style.transform = "translateX(" + slideAmount + "px)";
      counter++;
    }, 3500);

    return () => mounted = false;
  }, []);

  useEffect(() => {
    setMenuVisibility(false);
  }, [location]);

  return (
    <header>
      <div onClick={toggleMenuVisibility} className="fixed top-2 left-2 lg:hidden text-center cursor-pointer border-black border p-1 rounded-md bg-gray-100 z-30">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </div>
      <div className={!menuVisibility ? "hidden " : "" + "w-full h-screen fixed bg-black z-20 top-0 left-0 opacity-80 lg:hidden"}></div>
      <section className="grid items-center md:h-40 grid-cols-2 md:grid-cols-4 lg:grid-cols-8">
        {/* logo */}
        <div className="col-span-1 mr-2 md:mr-0 text-right lg:text-left">
          <Link to="/">
            <img src={require("../images/logo.png")} alt="logo" className="max-h-24 md:max-h-36 inline-block" />
          </Link>
        </div>
        {/* navigation */}
        <nav className="uppercase col-span-2 lg:col-span-6 order-last md:order-none lg:defaultMenu">
          <div className="text-gray-700 font-semibold text-2xl md:text-3xl lg:text-4xl my-2 md:mt-0 text-center"><Link to="/">Prof. Dr. Veysel Eroğlu</Link></div>
          <ul className={(menuVisibility ? "flex " : "hidden lg:flex ") + "gap-5 text-lg font-semibold lg:justify-center fixed lg:relative top-0 left-0 z-30 flex-col lg:flex-row h-screen lg:h-auto bg-gray-100 lg:bg-transparent p-6 pt-1 lg:p-0 menu"}>
            <span onClick={toggleMenuVisibility} className="inline-block absolute top-1 right-3 lg:hidden bg-black text-white w-8 text-center cursor-pointer z-40">X</span>
            <li><Link to="/">ANASAYFA</Link></li>
            <li><Link to="/hakkinda">HAKKINDA</Link></li>
            <li><Link to="/faaliyetler">FAALİYETLER</Link></li>
            <li><Link to="/il-yatirimlari">İL YATIRIMLARI</Link></li>
            <li className="relative group">
              GALERİ
              <ul className="lg:popupMenu">
                <li className="pl-3 lg:p-2"><Link to="/yurtici-seyahatler"><span className="lg:hidden">&#62;</span>YURTİÇİ SEYAHATLER</Link></li>
                <li className="pl-3 pt-3 lg:p-2"><Link to="/yurtdisi-seyahatler"><span className="lg:hidden">&#62;</span>YURTDIŞI SEYAHATLER</Link></li>
                <li className="pl-3 pt-3 lg:p-2"><Link to="/video-galeri"><span className="lg:hidden">&#62;</span>VİDEO GALERİ</Link></li>
              </ul>
            </li>
            <li><Link to="/iletisim">İLETİŞİM</Link></li>
          </ul>
        </nav>
        {/* flag */}
        <div className="col-span-1 ml-2 md:ml-0">
          <Link to="/">
            <img src={require("../images/turkish-flag.gif")} alt="Turkish flag" className="max-h-24 md:max-h-36 inline-block" />
          </Link>
        </div>
      </section>
      <section>
        {/* slider */}
        <div className="flex flex-col items-center overflow-x-hidden">
          <div className="slider w-full rounded flex mobileSlider lg:defaultSlider">
            {kayanFotograflar.map(
              (kayanFotograf, index) => <SliderImage key={"slider" + index} url={kayanFotograf} />
            )}
          </div>
        </div>
        {/* project counts */}
        <div className="grid gap-y-1 my-2 py-1 font-semibold bg-gray-700 text-white grid-cols-3 md:grid-cols-6 text-base md:text-lg">
          <Link to="/projeler/barajlar"><div className="projectCount"><CountUp duration={3.5} end={Number(projeSayilari.baraj)} suffix="<br />BARAJ" /></div></Link>
          <div className="projectCount"><CountUp duration={3.5} end={Number(projeSayilari.hes)} suffix="<br />HES" /></div>
          <div className="projectCount"><CountUp duration={3.5} end={Number(projeSayilari.goletVeBent)} suffix="<br />GÖLET VE BENT" /></div>
          <div className="bg-white col-span-3 h-0.5 md:hidden"></div>
          <div className="projectCount"><CountUp duration={3.5} end={Number(projeSayilari.icmeSuyu)} suffix="<br />İÇME SUYU" /></div>
          <div className="projectCount"><CountUp decimals={1} decimal="," duration={3.5} end={Number(projeSayilari.milyarFidan)} suffix="<br />MİLYAR FİDAN" /></div>
          <div className="projectCount"><CountUp duration={3.5} end={Number(projeSayilari.milliPark)} suffix="<br />MİLLİ PARK" /></div>
        </div>
      </section>
    </header>
  );
};

export default Top;