import React, { useEffect } from "react";

function WeatherForecast() {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = "!function (d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (!d.getElementById(id)) { js = d.createElement(s); js.id = id; js.src = 'https://weatherwidget.io/js/widget.min.js'; fjs.parentNode.insertBefore(js, fjs); } }(document, 'script', 'weatherwidget-io-js');";
    document.getElementsByClassName("weather-embed")[0].appendChild(script);
  }, []);

  return (
    <div className="weather-embed">
      <a className="weatherwidget-io" href="https://forecast7.com/tr/39d9332d86/ankara/" data-label_1="ANKARA"
        data-label_2="Hava Durumu" data-mode="Forecast" data-days="3">ANKARA Hava Durumu</a>
    </div>
  );
};

export default WeatherForecast;