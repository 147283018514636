import React from "react";
import { Link } from "react-router-dom";
const reactStringReplace = require('react-string-replace');

function MajorProject(props) {
  const backgrounds = [
    "projectBackground1",
    "projectBackground2",
    "projectBackground3",
    "projectBackground4",
    "projectBackground5",
    "projectBackground6"
  ];

  return (
    <Link to={"/projeler/" + props.url}>
      <div className={"lg:majorProject lg:" + backgrounds[props.background]}>
        {reactStringReplace(props.name, '*br*', (match, i) => (<br key={"lineBreak" + i} className="hidden lg:inline" />))}
      </div>
    </Link>
  );
};

export default MajorProject;