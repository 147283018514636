import React from "react";

function PublishedWork(props) {
  return (
    <a href={props.url} target="_blank">
      <div className="w-full flex border-t border-b border-black mb-2">
        <img src={props.image} alt="{props.name}"
          className="w-1/4 m-2 flex-shrink-0 object-contain" />
        <div className="mt-1 pb-1 w-full">
          <span className="text-blue-800 font-semibold text-lg md:text-base">{props.name}</span><br />
          <span className="text-base md:text-xs">
            <span>Yayın Tarihi: {props.date}<br /></span>
            {props.isbn ? <span>ISBN: {props.isbn}<br /></span> : ""}
            <span>Sayfa Sayısı: {props.pages}<br /></span>
          </span>
        </div>
      </div>
    </a>
  );
};

export default PublishedWork;