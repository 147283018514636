import React from "react";
import SocialAccount from "../Components/SocialAccount";
import TwitterTimeline from "../Components/TwitterTimeline";

function Left(props) {
  return (
    <section className="col-span-1 px-0.5 overflow-y-auto">
      <h2 className="text-lg font-semibold pl-4">Sosyal Medya Hesapları</h2>
      <SocialAccount name="Prof. Dr. Veysel Eroğlu" username="VeyselEroglu03" image={require("../images/eroglu.jpg")} platform="facebook" />

      <SocialAccount name="Veysel Eroğlu" username="veyseleroglu" image={require("../images/eroglu.jpg")} platform="instagram" />

      <TwitterTimeline username="VeyselEroglu" />
    </section>
  );
};

export default Left;