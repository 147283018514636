import React from "react";
import { Link } from "react-router-dom";

function News(props) {
  return (
    <div className="w-full h-full flex-shrink-0 relative">
      <Link to={"/haberler/" + props.url}>
        <img alt={props.headline}
          src={props.image}
          className="h-full w-full object-cover absolute inset-0 z-10" />
        <div className="w-full absolute bottom-0 opacity-80 bg-black z-20 text-white p-3 font-semibold text-xl md:text-2xl">
          {props.headline}
        </div>
      </Link>
    </div>
  );
};

export default News;