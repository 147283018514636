import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from "react-simple-captcha";

let form, setForm;
let history;

function handleChange(event) {
  const { name, value } = event.target;
  setForm({ ...form, [name]: value });
  if (name === "captcha") {
    document.querySelector("#captcha").classList.remove("bg-red-600");
  }
}

function handleSubmit() {
  event.preventDefault();
  if (validateCaptcha(form.captcha) == true) {

    fetch("https://api.slapform.com/eroglukarahisar@gmail.com", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "slap_subject": "İletişim Formundan Mesaj Var",
        "Ad Soyad": form.name,
        "Telefon Numarası": form.phone,
        "E-posta Adresi": form.email,
        "slap_replyto": form.email,
        "Mesaj": form.message,
        "slap_honey": form.slap_honey
      })
    }).then(
      response => response.json()
    ).then((response) => {
      history.push("/");
    });

  } else {
    document.querySelector("#captcha").classList.add("bg-red-600");
  }
}

function ContactContent(props) {
  [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    captcha: "",
    slap_honey: ""
  });

  history = useHistory();

  useEffect(() => {
    document.title = props.title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    loadCaptchaEnginge(6);
  }, []);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{props.title}</h1>
      <div className="flex justify-around flex-col gap-x-2 md:flex-row">
        <iframe className="h-96 w-full border-black md:w-4/6 md:pr-3 border-0 md:border-r-2" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJg2PORQlP0xQRK80CF9sP4Q8&key=AIzaSyBf1q_U0O0ax_ovfpC1o94B2uqRCEQbPd4"></iframe>
        <div className="text-lg md:text-xl w-full md:w-2/6 mt-4 md:mt-0">
          <span className="inline-block w-full mb-2"><span className="font-bold">Adres:</span><br />TBMM Halkla İlişkiler Binası Kat: 6 Oda No: F618 Çankaya/Ankara<br /></span>
          <span className="inline-block w-full mb-2"><span className="font-bold">Tel:</span><br />0312 420 64 78 – 79<br /></span>
          <span className="inline-block w-full mb-2"><span className="font-bold">Fax:</span><br />0312 420 27 33<br /></span>
          <span className="inline-block w-full mb-2"><span className="font-bold">E-posta:</span><br />veysel.eroglu<wbr />@tbmm.gov.tr</span>
        </div>
      </div>
      <div className="mt-8">
        <span className="font-bold">İletişim Formu</span>
        <form
          onSubmit={handleSubmit}
          style={{
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: "100%"
          }}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Ad Soyad
            </label>
            <input onChange={handleChange} value={form.name} name="name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Telefon Numarası
            </label>
            <input onChange={handleChange} value={form.phone} name="phone" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-posta Adresi
            </label>
            <input onChange={handleChange} value={form.email} name="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Mesajınız
              <textarea onChange={handleChange} value={form.message} name="message" className="shadow form-textarea mt-1 block w-full border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="5"></textarea>
            </label>
          </div>
          <div className="mb-4">
            <label className="flex items-center text-gray-700 text-sm font-bold mb-2" htmlFor="captcha">
              <LoadCanvasTemplateNoReload /> <span className="ml-2">Resimde gördüğünüz karakterleri yazınız.</span>
            </label>
            <input onChange={handleChange} value={form.captcha} name="captcha" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="captcha" type="text" />
          </div>
          <div className="flex items-center justify-between">
            <input onChange={handleChange} value={form.slap_honey} type="text" name="slap_honey" hidden />
            <button className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Gönder</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactContent;