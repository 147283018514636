import React, { useState, useEffect } from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import { client, renderComponents } from "../client";
import { correctDateFormat } from "../utils/dateUtils";

function NewsContent(props) {
  let mounted = true;

  const { news } = useParams();

  const [apiStatus, setApiStatus] = useState({
    called: false,
    notFound: false
  });
  const [haber, setHaber] = useState({
    "baslik": "",
    "baglanti": "",
    "tarih": "",
    "fotograf": "",
    "detay": ""
  });
  const [prevNews, setPrevNews] = useState(undefined);
  const [nextNews, setNextNews] = useState(undefined);

  useEffect(() => {
    client.getEntries({
      "content_type": "haberler",
      "limit": 1,
      "include": 10,
      'fields.baglanti': news
    })
      .then((response) => {
        if (mounted) {
          if (response.items.length > 0) {
            setHaber(response.items[0].fields);
            document.title = response.items[0].fields.baslik + " :: Prof. Dr. Veysel Eroğlu";
            window.scrollTo(0, 0);
            setApiStatus({ called: true, notFound: false });

            client.getEntries({
              content_type: "haberler",
              select: "fields.baglanti",
              skip: 0,
              limit: 1,
              order: 'fields.tarih',
              'fields.tarih[gt]': response.items[0].fields.tarih,
            })
              .then((responsePrev) => {
                if (mounted) {
                  if (responsePrev.items.length > 0) {
                    setPrevNews(responsePrev.items[0].fields.baglanti);
                  } else {
                    setPrevNews(undefined);
                  }
                }
              })
              .catch(console.error);

            client.getEntries({
              content_type: "haberler",
              select: "fields.baglanti",
              skip: 0,
              limit: 1,
              order: '-fields.tarih',
              'fields.tarih[lt]': response.items[0].fields.tarih,
            })
              .then((responseNext) => {
                if (mounted) {
                  if (responseNext.items.length > 0) {
                    setNextNews(responseNext.items[0].fields.baglanti);
                  } else {
                    setNextNews(undefined);
                  }
                }
              })
              .catch(console.error);
          } else {
            setApiStatus({ called: true, notFound: true });
          }
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, [news]);

  if (apiStatus.called === true && apiStatus.notFound === true) {
    return (
      <Redirect to="/" />
    );
  } else if (apiStatus.called === true && apiStatus.notFound === false) {
    return (
      <div className="w-full">
        <span className="inline-block w-1/2 text-blue-600 underline">{prevNews !== undefined ? <Link to={"/haberler/" + prevNews}>&#60;&#60; Önceki Haber</Link> : ""}</span>
        <span className="inline-block w-1/2 text-blue-600 underline text-right">{nextNews !== undefined ? <Link to={"/haberler/" + nextNews}>Sonraki Haber &#62;&#62;</Link> : ""}</span>
        <h1 className="mb-1 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{haber.baslik}</h1>
        <div className="mb-3 pb-2 pl-4 md:pl-8 border-b border-gray-700 font-medium">Yayınlama Tarihi: {correctDateFormat(haber.tarih)}</div>
        <div className="unreset">{renderComponents(haber.detay)}</div>
      </div>
    );
  } else {
    return (<div className="w-full"></div>);
  }
}

export default NewsContent;