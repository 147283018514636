import React from "react";
import facebookLogo from "../images/facebook-logo.png";
import instagramLogo from "../images/instagram-logo.png";

function SocialAccount(props) {
  const platforms = {
    facebook: {
      name: "Facebook",
      usernamePrefix: "",
      image: facebookLogo,
      baseUrl: "https://tr-tr.facebook.com/"
    },
    instagram: {
      name: "Instagram",
      usernamePrefix: "@",
      image: instagramLogo,
      baseUrl: "https://www.instagram.com/"
    }
  };
  return (
    <a href={platforms[props.platform].baseUrl + props.username} target="_blank">
      <div className="w-full flex border-t border-b border-black mb-4">
        <img src={props.image} alt={props.name}
          className="w-1/4 m-2 border flex-shrink-0 object-contain rounded-full" />
        <div className="relative pb-4 w-full mt-3">
          <span className="text-blue-800 font-semibold text-lg md:text-base">{props.name}</span>
          <span className="flex text-base md:text-xs">{platforms[props.platform].usernamePrefix + props.username}</span>
          <img src={platforms[props.platform].image} alt={platforms[props.platform].name} className="w-1/5 absolute right-1 bottom-1" />
        </div>
      </div>
    </a>
  );
};

export default SocialAccount;