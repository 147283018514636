import React from "react";
function formattedDate(d = new Date) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
}

function TodaysDate(props) {

  return (
    <div className="block text-sm text-center font-semibold border-t border-b border-black mt-3 py-1">
      {formattedDate()}
    </div>
  );
};

export default TodaysDate;