import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { client, renderComponents } from "../client";

const provinceUrlToDetails = {
  "adana": { title: "Adana", plateNo: 1 },
  "adiyaman": { title: "Adıyaman", plateNo: 2 },
  "afyonkarahisar": { title: "Afyonkarahisar", plateNo: 3 },
  "agri": { title: "Ağrı", plateNo: 4 },
  "amasya": { title: "Amasya", plateNo: 5 },
  "ankara": { title: "Ankara", plateNo: 6 },
  "antalya": { title: "Antalya", plateNo: 7 },
  "artvin": { title: "Artvin", plateNo: 8 },
  "aydin": { title: "Aydın", plateNo: 9 },
  "balikesir": { title: "Balikesir", plateNo: 10 },
  "bilecik": { title: "Bilecik", plateNo: 11 },
  "bingol": { title: "Bingöl", plateNo: 12 },
  "bitlis": { title: "Bitlis", plateNo: 13 },
  "bolu": { title: "Bolu", plateNo: 14 },
  "burdur": { title: "Burdur", plateNo: 15 },
  "bursa": { title: "Bursa", plateNo: 16 },
  "canakkale": { title: "Çanakkale", plateNo: 17 },
  "cankiri": { title: "Çankırı", plateNo: 18 },
  "corum": { title: "Çorum", plateNo: 19 },
  "denizli": { title: "Denizli", plateNo: 20 },
  "diyarbakir": { title: "Diyarbakır", plateNo: 21 },
  "edirne": { title: "Edirne", plateNo: 22 },
  "elazig": { title: "Elaziğ", plateNo: 23 },
  "erzincan": { title: "Erzincan", plateNo: 24 },
  "erzurum": { title: "Erzurum", plateNo: 25 },
  "eskisehir": { title: "Eskişehir", plateNo: 26 },
  "gaziantep": { title: "Gaziantep", plateNo: 27 },
  "giresun": { title: "Giresun", plateNo: 28 },
  "gumushane": { title: "Gümüşhane", plateNo: 29 },
  "hakkari": { title: "Hakkari", plateNo: 30 },
  "hatay": { title: "Hatay", plateNo: 31 },
  "isparta": { title: "Isparta", plateNo: 32 },
  "mersin": { title: "Mersin", plateNo: 33 },
  "istanbul": { title: "İstanbul", plateNo: 34 },
  "izmir": { title: "İzmir", plateNo: 35 },
  "kars": { title: "Kars", plateNo: 36 },
  "kastamonu": { title: "Kastamonu", plateNo: 37 },
  "kayseri": { title: "Kayseri", plateNo: 38 },
  "kirklareli": { title: "Kırklareli", plateNo: 39 },
  "kirsehir": { title: "Kırşehir", plateNo: 40 },
  "kocaeli": { title: "Kocaeli", plateNo: 41 },
  "konya": { title: "Konya", plateNo: 42 },
  "kutahya": { title: "Kütahya", plateNo: 43 },
  "malatya": { title: "Malatya", plateNo: 44 },
  "manisa": { title: "Manisa", plateNo: 45 },
  "kahramanmaras": { title: "Kahramanmaraş", plateNo: 46 },
  "mardin": { title: "Mardin", plateNo: 47 },
  "mugla": { title: "Muğla", plateNo: 48 },
  "mus": { title: "Muş", plateNo: 49 },
  "nevsehir": { title: "Nevşehir", plateNo: 50 },
  "nigde": { title: "Niğde", plateNo: 51 },
  "ordu": { title: "Ordu", plateNo: 52 },
  "rize": { title: "Rize", plateNo: 53 },
  "sakarya": { title: "Sakarya", plateNo: 54 },
  "samsun": { title: "Samsun", plateNo: 55 },
  "siirt": { title: "Siirt", plateNo: 56 },
  "sinop": { title: "Sinop", plateNo: 57 },
  "sivas": { title: "Sivas", plateNo: 58 },
  "tekirdag": { title: "Tekirdağ", plateNo: 59 },
  "tokat": { title: "Tokat", plateNo: 60 },
  "trabzon": { title: "Trabzon", plateNo: 61 },
  "tunceli": { title: "Tunceli", plateNo: 62 },
  "sanliurfa": { title: "Şanlıurfa", plateNo: 63 },
  "usak": { title: "Uşak", plateNo: 64 },
  "van": { title: "Van", plateNo: 65 },
  "yozgat": { title: "Yozgat", plateNo: 66 },
  "zonguldak": { title: "Zonguldak", plateNo: 67 },
  "aksaray": { title: "Aksaray", plateNo: 68 },
  "bayburt": { title: "Bayburt", plateNo: 69 },
  "karaman": { title: "Karaman", plateNo: 70 },
  "kirikkale": { title: "Kırıkkale", plateNo: 71 },
  "batman": { title: "Batman", plateNo: 72 },
  "sirnak": { title: "Şırnak", plateNo: 73 },
  "bartin": { title: "Bartın", plateNo: 74 },
  "ardahan": { title: "Ardahan", plateNo: 75 },
  "igdir": { title: "Iğdır", plateNo: 76 },
  "yalova": { title: "Yalova", plateNo: 77 },
  "karabuk": { title: "Karabük", plateNo: 78 },
  "kilis": { title: "Kilis", plateNo: 79 },
  "osmaniye": { title: "Osmaniye", plateNo: 80 },
  "duzce": { title: "Düzce", plateNo: 81 },
};

function plateNoToEntryId(plateNo) {
  if (plateNo < 21) {
    return "26uf4iuUGtVKL8lsn58wVr";
  } else if (plateNo < 41) {
    return "6bBkQULuj9s3tDFKxoII6Y";
  } else if (plateNo < 61) {
    return "10Ras2mMOlKX8vIGjYVEvW";
  } else {
    return "1YsARg1zwjzC6IuitRDyKE";
  }
}

function InvestmentContent(props) {
  let mounted = true;

  const { province } = useParams();
  if (provinceUrlToDetails[province] === undefined) {
    return <Redirect to="/" />;
  }
  const title = "İl Yatırımları: " + provinceUrlToDetails[province].title;

  const [yatirimlar, setYatirimlar] = useState({
  });
  useEffect(() => {
    document.title = title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntry(plateNoToEntryId(provinceUrlToDetails[province].plateNo))
      .then((response) => {
        if (mounted) {
          setYatirimlar(response.fields);
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, []);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{title}</h1>
      <div className="unreset">{renderComponents(yatirimlar[province])}</div>
    </div>
  );
};

export default InvestmentContent;