import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { client, renderComponents } from "../client";

function ProjectContent(props) {
  let mounted = true;
  const projectUrlToDetails = {
    "barajlar": { title: "Barajlar", fieldId: "barajlar" },
    "kktc-su-temin-projesi": { title: "Asrın Projesi KKTC Su Temin Projesi", fieldId: "kktcSuTeminProjesi" },
    "deriner-baraji": { title: "Ülkemizin En Yüksek Barajı Deriner Barajı", fieldId: "derinerBaraji" },
    "veysel-eroglu-baraji": { title: "Ilısu Prof. Dr. Veysel Eroğlu Barajı", fieldId: "veyselErogluBaraji" },
    "milli-agaclandirma-seferberligi": { title: "Milli Ağaçlandırma Seferberliği", fieldId: "agaclandirmaSeferberligi" },
    "canakkale-destani-tanitim-merkezi": { title: "Çanakkale Destanı Tanıtım Merkezi", fieldId: "canakkaleTanitimMerkezi" },
    "diger-projeler": { title: "Diğer Projeler", fieldId: "digerProjeler" }
  };

  const { project } = useParams();
  if (projectUrlToDetails[project] === undefined) {
    return <Redirect to="/" />;
  }
  const title = "Projeler: " + projectUrlToDetails[project].title;

  const [projeler, setProjeler] = useState({
    "barajlar": "",
    "kktc-su-temin-projesi": "",
    "deriner-baraji": "",
    "veysel-eroglu-baraji": "",
    "milli-agaclandirma-seferberligi": "",
    "canakkale-destani-tanitim-merkezi": "",
    "diger-projeler": ""
  });

  useEffect(() => {
    document.title = title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntry("4gjOBokSXTibVqzbo3wsve")
      .then((response) => {
        if (mounted) {
          setProjeler(response.fields);
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, [title]);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{title}</h1>
      <div className="unreset">{renderComponents(projeler[projectUrlToDetails[project].fieldId])}</div>
    </div>
  );
};

export default ProjectContent;