import React, { useState, useEffect } from "react";
import { client, renderComponents } from "../client";

function GeneralTextualContent(props) {
  let mounted = true;
  const title = props.title;
  const titleToEntryId = {
    "Hakkında": "4LDyZtmKgWnRXowK5QTq0u",
    "Faaliyetler": "1rK0D20vaAYabOgXzYtz1N"
  };

  const [sayfa, setSayfa] = useState({
    "sayfaIcerigi": ""
  });

  useEffect(() => {
    document.title = props.title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntry(titleToEntryId[title])
      .then((response) => {
        if (mounted) {
          setSayfa(response.fields);
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, [title]);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{title}</h1>
      <div className="unreset">{renderComponents(sayfa.sayfaIcerigi)}</div>
    </div>
  );
};

export default GeneralTextualContent;