import React, { useState, useEffect } from "react";
import { client } from "../client";


function PublishedWorksContent(props) {
  let mounted = true;
  const publishedWorksPerPage = 5;

  const [tumBasiliEserler, setTumBasiliEserler] = useState([]);
  const [basiliEserler, setBasiliEserler] = useState([]);
  const [pageCounts, setPageCounts] = useState({
    current: 0,
    total: 0
  });

  useEffect(() => {
    document.title = props.title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntries({
      content_type: "basiliEserleri",
      select: "fields.ad,fields.yil,fields.sayfa,fields.isbn,fields.kapak,fields.pdf",
      skip: 0,
      limit: 1000,
      order: '-fields.yil,fields.ad'
    })
      .then((response) => {
        if (mounted) {
          setTumBasiliEserler(response.items.map(basiliEser => basiliEser.fields));
          setPageCounts({
            current: 1,
            total: response.total <= response.limit ?
              Math.ceil(response.total / publishedWorksPerPage) :
              Math.ceil(response.limit / publishedWorksPerPage)
          });
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, []);

  useEffect(() => {
    if (mounted && pageCounts.current > 0) {
      setBasiliEserler(tumBasiliEserler.slice((pageCounts.current - 1) * publishedWorksPerPage, pageCounts.current * publishedWorksPerPage));
    }
  }, [pageCounts.current]);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{props.title}</h1>
      <div>{basiliEserler.map(
        (basiliEser) => <a href={basiliEser.pdf.fields.file.url} target="_blank"><div className="flex items-center gap-x-2 mb-6 bg-gray-200 rounded-md overflow-hidden shadow-lg border-gray-400 border-1">
          <img alt={basiliEser.ad} src={basiliEser.kapak.fields.file.url + "?w=120"} className="w-24 md:w-32" />
          <div>
            <span className="inline-block w-full mb-1 md:font-semibold md:text-xl">{basiliEser.ad}</span>
            <span className="inline-block w-full">Yayın Tarihi: {basiliEser.yil}</span>
            {basiliEser.isbn ? <span className="inline-block w-full">ISBN: {basiliEser.isbn}</span> : ""}
            <span className="inline-block w-full">Sayfa Sayısı: {basiliEser.sayfa}</span>
          </div>
        </div></a>
      )}
        <span className={"inline-block w-full text-center" + (pageCounts.total < 1 ? " hidden" : "")}>
          Toplam {tumBasiliEserler.length} basılı eserden {(pageCounts.current - 1) * publishedWorksPerPage + 1} ile {pageCounts.current < pageCounts.total ? pageCounts.current * publishedWorksPerPage : tumBasiliEserler.length} arasındakileri görüyorsunuz.<br />
          <select onChange={() => {
            const { value } = event.target;
            if (value !== "") {
              setPageCounts({ current: value, total: pageCounts.total });
            }
          }}>
            {[...Array(pageCounts.total)].map((x, i) => { return <option key={"page" + i} value={i + 1}>Sayfa {i + 1}</option>; })}
          </select> / {pageCounts.total}
        </span>
      </div>
    </div>
  );
};

export default PublishedWorksContent;