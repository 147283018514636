import React, { useState, useEffect } from "react";
import { client } from "../client";

function PhotoGalleryContent(props) {
  let mounted = true;
  const title = props.galleryType === "yurtici" ? "Yurtiçi Seyahatler" : "Yurtdışı Seyahatler";
  const galleryType = props.galleryType === "yurtici" ? "yurticiSeyahatler" : "yurtdisiSeyahatler";

  const [galeriIcerigi, setGaleriIcerigi] = useState([]);

  useEffect(() => {
    document.title = title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntry("2ExcaTTbtDjtSPe5Sk0f9d")
      .then((response) => {
        if (mounted) {
          if (response.fields[galleryType] !== undefined) {
            setGaleriIcerigi(response.fields[galleryType].map(fotograf => { return { url: fotograf.fields.file.url, title: fotograf.fields.title, description: fotograf.fields.description }; }));
            imgLightbox("zoomable");
          } else {
            setGaleriIcerigi([]);
          }
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, [galleryType]);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{title}</h1>
      <div className="grid gap-x-4 gap-y-8 justify-between grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {galeriIcerigi.map((fotograf, index) => {
          return (<a key={galleryType + index} href={fotograf.url} className="zoomable" data-src={fotograf.url} aria-hidden="true" rel="lightbox">
            <div className="bg-gray-200 rounded-md overflow-hidden shadow-lg border-gray-400 border-1">
              <span className="inline-block w-full"><img className="w-full" src={fotograf.url + "?h=250"} alt={fotograf.title} /></span>
              <span className="inline-block w-full px-2 pt-1 pb-1 font-semibold">{fotograf.title}</span>
              <span className="inline-block w-full text-sm px-2 pb-4">{fotograf.description}</span>
            </div>
          </a>);
        })}
      </div>
    </div >
  );
};

export default PhotoGalleryContent;