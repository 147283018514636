import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import PublishedWork from "../Components/PublishedWork";
import TodaysDate from "../Components/TodaysDate";
import WeatherForecast from "../Components/WeatherForecast";

function Right(props) {
  let mounted = true;
  const publishedWorksToShow = 3;

  const [basiliEserler, setBasiliEserler] = useState([]);

  useEffect(() => {
    client.getEntries({
      content_type: "basiliEserleri",
      select: "fields.ad,fields.yil,fields.sayfa,fields.isbn,fields.kapak,fields.pdf",
      skip: 0,
      limit: publishedWorksToShow,
      order: '-fields.yil,fields.ad'
    })
      .then((response) => {
        if (mounted) {
          setBasiliEserler(response.items.map(basiliEser => basiliEser.fields));
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, []);

  return (
    <section className="col-span-1 px-0.5 overflow-y-auto">
      <h2 className="text-lg font-semibold pl-4">En Yeni Basılı Eserleri</h2>
      {basiliEserler.map(
        (basiliEser, index) => <PublishedWork name={basiliEser.ad} image={basiliEser.kapak.fields.file.url} date={basiliEser.yil} isbn={basiliEser.isbn} pages={basiliEser.sayfa} url={basiliEser.pdf.fields.file.url} />
      )}
      <div className="text-right text-sm text-blue-600 underline border-b border-black pb-2"><Link to="/basili-eserleri">Tüm basılı eserleri görüntüle...</Link></div>

      <WeatherForecast />

      <TodaysDate />
    </section>
  );
};

export default Right;