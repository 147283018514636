import React from "react";
import FooterImage from "../Components/FooterImage";
import MajorProject from "../Components/MajorProject";

function Bottom(props) {
  return (
    <footer>
      {/* major projects */}
      <div className="bg-gray-700 text-white text-center gap-y-4 text-sm md:text-base py-3 mb-4 lg:py-0 lg:mb-0 grid grid-rows-1 lg:flex justify-evenly items-center mt-4 uppercase z-10 lg:bg-gray-100 lg:text-current">
        <MajorProject name="ASRIN PROJESİ KKTC SU TEMİN PROJESİ" background="0" url="kktc-su-temin-projesi" />
        <MajorProject name="ÜLKEMİZİN EN YÜKSEK BARAJI DERİNER BARAJI" background="1" url="deriner-baraji" />
        <MajorProject name="ILISU PROF. DR. VEYSEL EROĞLU BARAJI" background="2" url="veysel-eroglu-baraji" />
        <MajorProject name="MİLLİ AĞAÇLANDIRMA SEFERBERLİĞİ" background="3" url="milli-agaclandirma-seferberligi" />
        <MajorProject name="ÇANAKKALE DESTANI TANITIM MERKEZİ" background="4" url="canakkale-destani-tanitim-merkezi" />
        <MajorProject name="*br*Diğer Projeler*br**br*" background="5" url="diger-projeler" />
      </div>
      <div className="w-full h-20 bottom-0 bg-gray-700 -mt-16 mb-4 hidden lg:block"></div>

      {/* useful links */}
      <div className="flex flex-wrap gap-y-1 justify-evenly">
        <FooterImage name="T.C. Cumhurbaşkanlığı" image={require("../images/cumhurbaskanligi.png")} url="https://www.tccb.gov.tr" />
        <FooterImage name="Türkiye Büyük Millet Meclisi" image={require("../images/tbmm.png")} url="https://www.tbmm.gov.tr" />
        <FooterImage name="Ak Parti" image={require("../images/ak-parti.png")} url="https://www.akparti.org.tr" />
        <FooterImage name="Afyonkarahisar Valiliği" image={require("../images/afyonkarahisar-valiligi.png")} url="http://www.afyonkarahisar.gov.tr" />
        <FooterImage name="Afyonkarahisar Belediyesi" image={require("../images/afyonkarahisar-belediyesi.png")} url="https://www.afyon.bel.tr" />
        <FooterImage name="Devlet Su İşleri" image={require("../images/dsi.png")} url="https://www.dsi.gov.tr" />
        <FooterImage name="Su Yönetimi Genel Müdürlüğü" image={require("../images/su-yonetimi.png")} url="https://www.tarimorman.gov.tr/SYGM" />
        <div className="flex-breaker lg:hidden"></div>
        <FooterImage name="Türkiye Su Enstitüsü" image={require("../images/su-enstitusu.png")} url="https://www.suen.gov.tr" />
        <FooterImage name="Meteoroloji Genel Müdürlüğü" image={require("../images/meteoroloji.png")} url="https://www.mgm.gov.tr" />
        <FooterImage name="İstanbul Su ve Kanalizasyon İdaresi" image={require("../images/iski.png")} url="https://www.iski.istanbul" />
        <FooterImage name="Orman Genel Müdürlüğü" image={require("../images/ogm.png")} url="https://www.ogm.gov.tr" />
        <FooterImage name="Milli Parklar Genel Müdürlüğü" image={require("../images/milli-parklar.png")} url="https://www.tarimorman.gov.tr/DKMP" />
        <FooterImage name="Çölleşme ve Erozyonla Mücadele Genel Müdürlüğü" image={require("../images/cem.png")} url="https://www.tarimorman.gov.tr/CEM" />
      </div>
    </footer>
  );
};

export default Bottom;