import React from "react";

function SliderImage(props) {
  return (
    <div className="w-full h-full flex-shrink-0">
      <img src={props.url} className="h-full w-full object-cover" />
    </div>
  );
};

export default SliderImage;