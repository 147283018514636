import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import { correctDateFormat } from "../utils/dateUtils";


function AllNewsContent(props) {
  let mounted = true;
  const newsPerPage = 10;

  const [tumHaberler, setTumHaberler] = useState([]);
  const [haberler, setHaberler] = useState([]);
  const [pageCounts, setPageCounts] = useState({
    current: 0,
    total: 0
  });

  useEffect(() => {
    document.title = props.title + " :: Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntries({
      content_type: "haberler",
      select: "fields.baslik,fields.baglanti,fields.tarih,fields.fotograf",
      skip: 0,
      limit: 1000,
      order: '-fields.tarih'
    })
      .then((response) => {
        if (mounted) {
          setTumHaberler(response.items.map(haber => haber.fields));
          setPageCounts({
            current: 1,
            total: response.total <= response.limit ?
              Math.ceil(response.total / newsPerPage) :
              Math.ceil(response.limit / newsPerPage)
          });
        }
      })
      .catch(console.error);

    return () => mounted = false;
  }, []);

  useEffect(() => {
    if (mounted && pageCounts.current > 0) {
      setHaberler(tumHaberler.slice((pageCounts.current - 1) * newsPerPage, pageCounts.current * newsPerPage));
    }
  }, [pageCounts.current]);

  return (
    <div className="w-full">
      <h1 className="mb-3 pb-2 border-b border-gray-700 font-semibold pl-4 md:pl-8 text-xl md:text-3xl">{props.title}</h1>
      <div>{haberler.map(
        (haber, index) => <Link key={index} to={"/haberler/" + haber.baglanti}><div className="flex items-center gap-x-2 mb-6 bg-gray-200 rounded-md overflow-hidden shadow-lg border-gray-400 border-1">
          <img alt={haber.baslik} src={haber.fotograf.fields.file.url + "?w=140"} className="w-28 md:w-36" />
          <div>
            <span className="inline-block w-full mb-1 md:font-semibold md:text-xl">{haber.baslik}</span>
            <span className="inline-block w-full">({correctDateFormat(haber.tarih)})</span>
          </div>
        </div></Link>
      )}
        <span className={"inline-block w-full text-center" + (pageCounts.total < 1 ? " hidden" : "")}>
          Toplam {tumHaberler.length} haberden {(pageCounts.current - 1) * newsPerPage + 1} ile {pageCounts.current < pageCounts.total ? pageCounts.current * newsPerPage : tumHaberler.length} arasındakileri görüyorsunuz.<br />
          <select onChange={() => {
            const { value } = event.target;
            if (value !== "") {
              setPageCounts({ current: value, total: pageCounts.total });
            }
          }}>
            {[...Array(pageCounts.total)].map((x, i) => { return <option key={"page" + i} value={i + 1}>Sayfa {i + 1}</option>; })}
          </select> / {pageCounts.total}
        </span>
      </div>
    </div>
  );
};

export default AllNewsContent;