import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import News from "../Components/News";
import { client } from "../client";
import SwipeListener from "swipe-listener";

let currentNews = 0;
function slideTo(targetNews) {
  const slider2 = document.querySelector('.slider2');
  const newsNavigation = document.querySelector('.newsNavigation');
  
  let slideAmount2;

  if (targetNews === "prev" && currentNews > 0) {
    targetNews = currentNews - 1;
  } else if (targetNews === "next" && currentNews < slider2.childElementCount - 1) {
    targetNews = currentNews + 1;
  } else if(targetNews === "prev" || targetNews === "next") {
    //out of limit swipe
    targetNews = currentNews;
  }

  newsNavigation.getElementsByTagName("span")[currentNews].classList.remove("sliderNavigationCurrentItem");
  newsNavigation.getElementsByTagName("span")[targetNews].classList.add("sliderNavigationCurrentItem");

  if (currentNews !== targetNews) {
    slideAmount2 = -1 * targetNews * slider2.clientHeight - 1;
    slider2.style.transform = "translateY(" + slideAmount2 + "px)";
    currentNews = targetNews;
  }
}

function HomepageContent(props) {
  let mounted = true;

  const [haberler, setHaberler] = useState([]);

  useEffect(() => {
    document.title = "Prof. Dr. Veysel Eroğlu";
    window.scrollTo(0, 0);
    client.getEntries({
      "content_type": "haberler",
      limit: 8,
      order: '-fields.tarih'
    })
      .then((response) => {
        if (mounted) {
          setHaberler(response.items.map(haber => haber.fields));
        }
      })
      .catch(console.error);

    const slider2 = document.querySelector('.slider2');
    SwipeListener(slider2);
    slider2.addEventListener('swipe', (event) => {
      let directions = event.detail.directions;

      if (directions.left) {
        slideTo("next");
      } else if (directions.right) {
        slideTo("prev");
      }
    });

    return () => mounted = false;
  }, []);

  useEffect(() => {
    const slider2 = document.querySelector('.slider2');
    const newsNavigation = document.querySelector('.newsNavigation');

    for (let i = 0; i < slider2.childElementCount; i++) {
      let navigationElement = document.createElement("span");
      navigationElement.classList.add("sliderNavigationItem");
      navigationElement.innerHTML = i + 1;
      navigationElement.onclick = () => slideTo(i);
      newsNavigation.appendChild(navigationElement);
    }
    if (newsNavigation.getElementsByTagName("span")[0] !== undefined) {
      newsNavigation.getElementsByTagName("span")[0].classList.add("sliderNavigationCurrentItem");
    }
  }, [haberler]);

  return (
    <div className="w-full mobileNews lg:defaultNews">
      <div className="overflow-y-hidden" style={{ height: '85%' }}>
        <div className="slider2 w-full h-full rounded flex flex-col">
          {haberler.map(
            (haber, index) => <News key={"news" + index} headline={haber.baslik} image={haber.fotograf.fields.file.url + "?h=560"} url={haber.baglanti} />
          )}
        </div>
      </div>
      <div className="newsNavigation flex justify-center mt-3 gap-4 xl:gap-7"></div>
      <div className="text-right text-sm my-3 pr-4 text-blue-600 underline"><Link to="/haberler">Geçmiş haberlere erişmek için tıklayınız.</Link></div>
    </div>
  );
};

export default HomepageContent;